<template>
    <div class="al-content">
        <div class="content-top">
            <div class="content-top clearfix">
                <h1 class="al-title ">Create Import Order</h1>
            </div>
        </div>


        <div>
            <div class="panel panel-blur with-scroll animated zoomIn">
                <div class="panel-heading clearfix">
                    <h6 class="panel-title">Information</h6>
                </div>
                <div class="panel-body">

                    <div class="row">
                        <label for="ordername" class="col-sm-2 control-label mt-2">Order Name</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control mb-1" id="ordername" @keyup="nameKeyup" />
                            <i class="ion-alert-circled form-control-feedback"></i>
                            <span v-show="OrderNameError!=''" id="OrderNameError" class="help-block red-text basic-block">Please input a Name.</span>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <label for="OrderDate" class="col-sm-2 control-label  mt-2">Order Date</label>
                        <div class="col-sm-10">
                            <Datepicker v-model="OrderDate" id="OrderDate" autoApply monthPicker style="max-width:250px" format="01/MM/yyyy" :enableTimePicker="false">
                                <template #calendar-header="{ index}">
                                    <div :class="index === 5 || index === 6 ? 'calenda-red-color' : ''">

                                    </div>
                                </template>
                            </Datepicker>
                            <span v-show="OrderDate==null" id="OrderDateError" class="help-block red-text basic-block">Please input a date</span>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <label for="ReceiveDate" class="col-sm-2 control-label  mt-2">Receive Date</label>
                        <div class="col-sm-10">
                            <Datepicker v-model="ReceiveDate" autoApply monthPicker style="max-width:250px" format="01/MM/yyyy" :enableTimePicker="false">
                                <template #calendar-header="{ index}">
                                    <div :class="index === 5 || index === 6 ? 'calenda-red-color' : ''">

                                    </div>
                                </template>
                            </Datepicker>
                            <span v-show="ReceiveDate==null" id="ReceiveDateError" class="help-block red-text basic-block ">Please input a date</span>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <label for="SoldOutDate" class="col-sm-2 control-label  mt-2">SoldOut Date</label>
                        <div class="col-sm-10">
                            <Datepicker v-model="SoldOutDate" autoApply monthPicker style="max-width:250px" format="01/MM/yyyy" :enableTimePicker="false">
                                <template #calendar-header="{ index}">
                                    <div :class="index === 5 || index === 6 ? 'calenda-red-color' : ''">

                                    </div>
                                </template>
                            </Datepicker>
                            <span v-show="SoldOutDate==null" id="SoldOutDateError" class="help-block red-text basic-block">Please input a date</span>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <label for="Quantity" class="col-sm-2 control-label  mt-2">Quantity</label>
                        <div class="col-sm-10">
                            <div>
                                <button id="Quantity" type="button" class="btn btn-default btn-xs dropdown-toggle font-x3 btn-lg"
                                        data-bs-toggle="dropdown">
                                    <h5 style="float:left;margin-bottom:0px">{{formatNumber(Quantity)}}</h5>
                                </button>
                                <ul class="dropdown-menu">
                                    <li v-for="item in dataOrder" :key="item.Id">
                                        <a class="dropdown-item" href="javascript:void(0);" @click="Quantity=item.Quantity">{{item.Quantity}}</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" href="javascript:void(0);" @click="showCustomQuantity=true">Custom</a>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <span v-show="Quantity<1000" id="QuantityError" class="help-block red-text basic-block">Invalid Number! Must greater than 1000.</span>
                            </div>
                        </div>
                    </div>


                </div>
                <div class="panel-heading clearfix">
                    <h6 class="panel-title">Data selected</h6>
                </div>
                <div class="panel-body">
                    <div class="row mt-2">
                        <label for="Catalog" class="col-sm-2 control-label  mt-2">Supplier:</label>
                        <div class="col-sm-10">
                            <div>
                                <button id="Quantity" type="button" class="btn btn-default btn-xs dropdown-toggle"
                                        data-bs-toggle="dropdown">
                                    <h6 style="float:left;margin-bottom:0px">{{Catalog}}</h6>
                                </button>
                                <ul class="dropdown-menu">
                                    <li v-for="item in dataCatalog" :key="item.Id">
                                        <a class="dropdown-item" href="javascript:void(0);" @click="Catalog=item">{{item}}</a>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                    <div class="row mt-2">
                        <label for="LastInventoryFileName" class="col-sm-2 control-label  mt-2">Last Inventory <br />(Tồn kho tháng trước)</label>
                        <div class="col-sm-10">
                            <div>
                                <button id="LastInventoryFileName" type="button" class="btn btn-default btn-xs dropdown-toggle"
                                        data-bs-toggle="dropdown">
                                    <div style="float:left;margin-bottom:0px;text-transform:none!important;">{{LastInventoryFileName}}</div>
                                </button>
                                <ul class="dropdown-menu">
                                    <li v-for="item in InventoryFileIds" :key="dataInventory[item].Id">
                                        <a class="dropdown-item" href="javascript:void(0);" @click="LastInventoryFileId=dataInventory[item].Id">{{dataInventory[item].FileName}}</a>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <span v-show="LastInventoryFileId==0" id="LastInventoryFileError" class="help-block red-text basic-block">Please choose a File</span>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <label for="CurrentInventoryFileName" class="col-sm-2 control-label  mt-2">Current Inventory<br />(Tồn Kho hiện tại)</label>
                        <div class="col-sm-10">
                            <div>
                                <button id="CurrentInventoryFileName" type="button" class="btn btn-default btn-xs dropdown-toggle"
                                        data-bs-toggle="dropdown">
                                    <div style="float:left;margin-bottom:0px;text-transform:none!important;">{{CurrentInventoryFileName}}</div>
                                </button>
                                <ul class="dropdown-menu">
                                    <li v-for="item in InventoryFileIds" :key="dataInventory[item].Id">
                                        <a class="dropdown-item" href="javascript:void(0);" @click="CurrentInventoryFileId=dataInventory[item].Id">{{dataInventory[item].FileName}}</a>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <span v-show="CurrentInventoryFileId==0" id="CurrentInventoryFileError" class="help-block red-text basic-block">Please choose a File</span>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="panel-heading clearfix">
                    <h6 class="panel-title">Table Details</h6>
                </div>
               
                <div class="panel-body" style="padding:0px" id="fakeScrollDiv">

                    <div class="wrapper1" style="height:1px" id="fakeScroll">
                    </div>

                </div>
                <div class="panel-body" id="mainScrollDiv">
                    <div class="row mt-3">

                        <label class="checkbox-inline custom-checkbox nowrap me-3">
                            <input type="checkbox" @click="isFreezeHeader=!isFreezeHeader" :checked="isFreezeHeader?true:null">
                            <span>Freeze Header</span>
                        </label>
                    </div>
                    <div class="row mt-3" :class="isFreezeHeader?'freezeHeader':''">
                        <table class="table table-hover table-bordered" id="datatableProductSold" v-show="Catalog">
                            <thead>
                                <tr>
                                    <th colspan="8"><h3>Inventory</h3></th>
                                    <th :colspan="1+GroupSaleSelectedIds.length">
                                        <a class="editable-click"
                                           data-bs-toggle="tooltip"
                                           data-bs-placement="top"
                                           title="Manage GroupSale"
                                           @click="showGroupSale=true"
                                           style="font-size:24px;text-align:center">
                                            Group Sales
                                        </a>
                                    </th>
                                    <th colspan="6" class="text-center"><h3>Total Order</h3></th>
                                </tr>
                                <tr>
                                    <!--========== Inventory ===========-->
                                    <th>A. Name</th>
                                    <th>B. Code</th>
                                    <th>C. Last Inventory&nbsp;<br />(Tồn Kho tháng trước)</th>
                                    <th>D. Current Inventory&nbsp;<br />(Tồn Kho hiện tại)</th>
                                    <th>E. Regular Sold&nbsp;<br />(DS mùa thấp điểm)</th>
                                    <th>F. Peak Sold&nbsp;<br />(DS mùa cao điểm)</th>
                                    <th>
                                        <a class="editable-click"
                                           data-bs-toggle="tooltip"
                                           data-bs-placement="top"
                                           title="Click to select"
                                           @click="showFormular('ReceiveMonths')">
                                            G. Revenue Until Receive&nbsp;<br />(DS đến ngày nhận hàng) &nbsp;<br />({{RevenueUntilReceiveMonths}} months)
                                        </a>
                                    </th>
                                    <th>
                                        <a class="editable-click"
                                           data-bs-toggle="tooltip"
                                           data-bs-placement="top"
                                           title="Click to select"
                                           @click="showFormular('SoldOutMonths')">
                                            H. Revenue Until SoldOut&nbsp;<br />(DS đến ngày hết hàng)&nbsp;<br />({{RevenueUntilSoldOutMonths}} months)
                                        </a>
                                    </th>

                                    <!--========== GroupSale ===========-->
                                    <th v-for="(item,index) in GroupSaleSelectedIds" :key="item">{{alphabetMap[inventoryColumnCount+index+1]}}. {{dataGroupSale[item].Name}}</th>
                                    <th>
                                        <a class="editable-click"
                                           data-bs-toggle="tooltip"
                                           data-bs-placement="top"
                                           title="Click to select"
                                           @click="showFormular('TotalSales')">
                                            {{alphabetMap[inventoryColumnCount+GroupSaleSelectedIds.length+1]}}. Total Sale&nbsp;<br />
                                            (đến ngày bán hết hàng)
                                        </a>


                                    </th>

                                    <!--========== Total Order ===========-->
                                    <th>Note</th>
                                    <th style="min-width:50px">
                                        <a class="editable-click"
                                           data-bs-toggle="tooltip"
                                           data-bs-placement="top"
                                           title="Click to select"
                                           @click="showFormular('Estimate')">
                                            {{alphabetMap[inventoryColumnCount+GroupSaleSelectedIds.length+3]}}. Estimate Order&nbsp;<br />(SL chai tạm tính)
                                        </a>

                                    </th>
                                    <th>
                                        <div style="width:100px">
                                            <a class="editable-click"
                                               data-bs-toggle="tooltip"
                                               data-bs-placement="top"
                                               title="Click to select"
                                               @click="(showRevenueSelected=true)&&(RevenueType=1)" v-html="(Revenue1FileName==''?'Reference Revenue from Order date to Receipt Date':Revenue1FileName)+' <br />(Tham khảo DS bán ra từ ngày đặt hàng đến ngày nhận)'">

                                            </a>
                                        </div>
                                    </th>
                                    <th>
                                        <a class="editable-click"
                                           data-bs-toggle="tooltip"
                                           data-bs-placement="top"
                                           title="Click to select"
                                           @click="(showRevenueSelected=true)&&(RevenueType=2)" v-html="(Revenue2FileName==''?'Reference Revenue from Receipt Date to Sold Out Date':Revenue2FileName)+' <br />(Tham khảo DS bán ra từ ngày nhận đến ngày bán hết)'">

                                        </a>
                                    </th>
                                    <th>{{alphabetMap[inventoryColumnCount+GroupSaleSelectedIds.length+6]}}. Order at Sea&nbsp;<br />(Hàng Trên Biển)</th>
                                    <th>
                                        <a class="editable-click"
                                           data-bs-toggle="tooltip"
                                           data-bs-placement="top"
                                           title="Click to select"
                                           @click="showFormular('Final')">
                                            {{alphabetMap[inventoryColumnCount+GroupSaleSelectedIds.length+7]}}. Final Order&nbsp;<br />(SL chai cần đặt)
                                        </a>

                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="item in dataProductCatalog" :key="item.id" >
                                    <tr style="background: #00000094" v-if="item.changeColor">
                                        <td :colspan="15+GroupSaleSelectedIds.length">{{item.SubCatalog2}}</td>
                                    </tr>
                                    <tr  >
                                        <!--========== Inventory ===========-->
                                        <td @click="ViewHistory(item.Code,item.Name)">
                                            <a href="javascript:void(0);"
                                               class="editable-click"
                                               data-bs-toggle="tooltip" data-bs-placement="top"
                                               title="View History">
                                                {{item.Name}}
                                            </a>
                                        </td>

                                        <td>{{item.Code}}</td>
                                        <td>{{formatNumber(item.LastInventory)}}</td>
                                        <td>{{formatNumber(item.CurrentInventory)}}</td>
                                        <td>{{formatNumber(item.RegularSold)}}</td>
                                        <td>{{formatNumber(item.PeakSold)}}</td>
                                        <td>{{formatNumber(item.RevenueUntilReceive)}}</td>
                                        <td>{{formatNumber(item.RevenueUntilSoldOut)}}</td>

                                        <!--========== GroupSale ===========-->
                                        <td v-for="(groupsale, key) in ProductGroupSaleData" :key="key">
                                            <div class="input-group-sm" v-show="ProductGroupSaleData[key][item.Code].isEdit">
                                                <input type="text" class="form-control" aria-label="" :value="ProductGroupSaleData[key][item.Code].value" :id="`ProductGroupSaleData_${item.Code}_${key}`">
                                                <button type="submit" class="btn btn-primary btn-sm" title="Submit" aria-label="Submit" @click="saveProductGroupSale(item.Code,key)">
                                                    <i class="ion-checkmark-round"></i>
                                                </button>
                                                <button class="btn btn-default  btn-sm" type="button" id="button-addon2" @click="ProductGroupSaleData[key][item.Code].isEdit=false"><i class="ion-close-round"></i></button>
                                            </div>

                                            <a class="editable-click"
                                               data-bs-toggle="tooltip"
                                               data-bs-placement="top"
                                               title="Click to edit"
                                               @click="ProductGroupSaleData[key][item.Code].isEdit=true"
                                               v-show="!ProductGroupSaleData[key][item.Code].isEdit">
                                                {{formatNumber(ProductGroupSaleData[key][item.Code].value)}}
                                            </a>

                                        </td>
                                        <td>{{formatNumber(item.TotalSale)}}</td>

                                        <!--========== Total Order ===========-->
                                        <td>
                                            <div class="input-group-sm" v-show="item.isEditProductOrderNote">
                                                <input type="text" class="form-control" aria-label="" :value="item.ProductOrderNote=='note'?'':item.ProductOrderNote" :id="`ProductProductOrderNote_${item.Code}`">
                                                <button type="submit" class="btn btn-primary btn-sm" title="Submit" aria-label="Submit" @click="saveProductProductOrderNote(item.Code)">
                                                    <i class="ion-checkmark-round"></i>
                                                </button>
                                                <button class="btn btn-default  btn-sm" type="button" id="button-addon2" @click="item.isEditProductOrderNote=false"><i class="ion-close-round"></i></button>
                                            </div>

                                            <a class="editable-click"
                                               data-bs-toggle="tooltip"
                                               data-bs-placement="top"
                                               title="Click to edit"
                                               @click="item.isEditProductOrderNote=true"
                                               v-show="!item.isEditProductOrderNote">
                                                {{item.ProductOrderNote==''?'note':item.ProductOrderNote}}
                                            </a>
                                        </td>
                                        <td v-html="showHtmlNumber(item.EstimateOrder)"> </td>
                                        <td>{{formatNumber(item.Revenue1)}}</td>
                                        <td>{{formatNumber(item.Revenue2)}}</td>
                                        <td>
                                            <div class="input-group-sm" v-show="item.isOldOrderEdit">
                                                <input type="text" class="form-control" aria-label="" :value="item.OldOrder" :id="`ProductOldOrderData_${item.Code}`">
                                                <button type="submit" class="btn btn-primary btn-sm" title="Submit" aria-label="Submit" @click="saveProductOldOrder(item.Code)">
                                                    <i class="ion-checkmark-round"></i>
                                                </button>
                                                <button class="btn btn-default  btn-sm" type="button" id="button-addon2" @click="item.isOldOrderEdit=false"><i class="ion-close-round"></i></button>
                                            </div>

                                            <a class="editable-click"
                                               data-bs-toggle="tooltip"
                                               data-bs-placement="top"
                                               title="Click to edit"
                                               @click="item.isOldOrderEdit=true"
                                               v-show="!item.isOldOrderEdit">
                                                {{formatNumber(item.OldOrder)}}
                                            </a>
                                        </td>
                                        <td v-html="showHtmlNumber(item.FinalOrder)"> </td>
                                    </tr>
                                </template>
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td style="text-align:right"><b>Total</b></td>
                                    <td></td>
                                    <td>{{formatNumber(footerData.LastInventory)}}</td>
                                    <td>{{formatNumber(footerData.CurrentInventory)}}</td>
                                    <td>{{formatNumber(footerData.RegularSold)}}</td>
                                    <td>{{formatNumber(footerData.PeakSold)}}</td>
                                    <td>{{formatNumber(footerData.RevenueUntilReceive)}}</td>
                                    <td>{{formatNumber(footerData.RevenueUntilSoldOut)}}</td>
                                    <!--========== GroupSale ===========-->
                                    <td v-for="(groupsale, key) in ProductGroupSaleData" :key="key">
                                        {{formatNumber(footerDataGroupSale[key])}}
                                    </td>
                                    <td>{{formatNumber(footerData.TotalSale)}}</td>
                                    <!--========== Total Order ===========-->
                                    <td></td>
                                    <td v-html="showHtmlNumber(footerData.EstimateOrder)"></td>
                                    <td>{{formatNumber(footerData.Revenue1)}}</td>
                                    <td>{{formatNumber(footerData.Revenue2)}}</td>
                                    <td>{{formatNumber(footerData.OldOrder)}}</td>
                                    <td v-html="showHtmlNumber(footerData.FinalOrder)"></td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                    <div style="clear:both"></div>
                    <div class="mt-3" v-show="Catalog">
                        <div class="text-center">
                            <button class="btn btn-primary" @click.prevent="save">{{SaveBtnTitle}}</button>
                            <button class="btn btn-secondary" @click.prevent="ToExcel" v-show="checkAcl('ExportExcel')">To Excel</button>
                            <button class="btn btn-danger ml-3" @click.prevent="$router.push('/ImportOrder')">Cancel</button>
                        </div>
                    </div>

                </div>

            </div>
        </div>

        <MDBModal id="customQuantityModal"
                  tabindex="-1"
                  staticBackdrop
                  labelledby="exampleModalLabel"
                  v-model="showCustomQuantity"
                  size="sm">
            <MDBModalHeader>
                <MDBModalTitle>Input Quantity</MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>

                <MDBRow id="configForm" tag="form" class="g-3">
                    <MDBCol md="12">
                        <MDBInput label="Quantity"
                                  v-model="CustomQuantity"
                                  id="CustomQuantity"
                                  invalidFeedback="Invalid! Must greater than 1000."
                                  validFeedback="Looks good!"
                                  type="number"
                                  minLength="3"
                                  title="Invalid! Must greater than 1000."
                                  required />

                    </MDBCol>
                </MDBRow>

            </MDBModalBody>
            <MDBModalFooter>
                <button type="submit" class="btn btn-success" @click="doCustomQuantity">
                    OK
                </button>
                <button type="button" class="btn btn-danger" @click="showCustomQuantity=false">
                    Cancel
                </button>

            </MDBModalFooter>
        </MDBModal>

        <MDBModal id="GroupSaleModal"
                  tabindex="-1"
                  staticBackdrop
                  labelledby="exampleModalLabel"
                  v-model="showGroupSale">
            <MDBModalHeader>
                <MDBModalTitle>GroupSale Selected</MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>

                <MDBRow id="assignForm" tag="form" class="g-3">
                    <MDBCol md="12" v-for="item in dataGroupSale" :key="item.Id" v-show="(!item.IsDisable&&!item.IsRemoved)||checkGroupSaleSelected(item.Id)">
                        <div class="form-check">
                            <input class="form-check-input fontnormal" type="checkbox" name="GroupSaleSelected" :id="`groupsaleid`+item.Id" aria-required="false"
                                   :checked="checkGroupSaleSelected(item.Id)"
                                   :disabled="item.IsDisable||item.IsRemoved?true:null"
                                   :value="item.Id">
                            <label class="form-check-label" :for="`groupsaleid`+item.Id">{{item.Name}} {{item.IsDisable?"(Disabled)":""}} <span style="color: blue; font-weight: bold" v-show="item.IsNew">(New)</span> <span style="color:red;font-weight:bold" v-show="item.IsRemoved">(Removed)</span></label>
                        </div>
                    </MDBCol>
                </MDBRow>

            </MDBModalBody>
            <MDBModalFooter>
                <button type="submit" class="btn btn-success" @click="doGroupSaleAdd">
                    OK
                </button>
                <button type="submit" class="btn btn-primary" @click="showCreateGroupSalesPopup">
                    Add Group
                </button>
                <button type="button" class="btn btn-danger" @click="showGroupSale=false">
                    Cancel
                </button>

            </MDBModalFooter>
        </MDBModal>


        <MDBModal id="createGroupSaleModal"
                  tabindex="-1"
                  staticBackdrop
                  labelledby="exampleModalLabel"
                  v-model="showCreateGroupSales"
                  size="lg">
            <MDBModalHeader>
                <MDBModalTitle id="editModalTitle">Add GroupSale</MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>

                <MDBRow id="editForm" tag="form" class="g-3">

                    <MDBCol md="12">
                        <MDBInput label="Group Name *"
                                  id="CreateGroupSaleName"
                                  v-model="CreateGroupSaleName"
                                  invalidFeedback="Please provide a valid Name"
                                  validFeedback="Looks good!"
                                  title="NO special characters, the length should be between 2 and  50 characters" />
                    </MDBCol>
                </MDBRow>

            </MDBModalBody>
            <MDBModalFooter>
                <button type="submit" class="btn btn-success" @click="doCreateGroupSales">
                    Add
                </button>
                <button type="button" class="btn btn-danger" @click="showCreateGroupSales=false">
                    Cancel
                </button>

            </MDBModalFooter>
        </MDBModal>


        <MDBModal id="selectedRevenueModal"
                  tabindex="-1"
                  staticBackdrop
                  labelledby="exampleModalLabel"
                  v-model="showRevenueSelected"
                  size="lg">
            <MDBModalHeader>
                <MDBModalTitle id="editModalTitle">Revenue Select</MDBModalTitle>
            </MDBModalHeader>
            <MDBModalBody>

                <MDBRow id="editForm" tag="form" class="g-3">

                    <MDBCol md="12">
                        <ul>
                            <li v-for="item in dataRevenue" :key="item.Id">
                                <a class="dropdown-item" href="javascript:void(0);" @click="selectRevenue(item)">{{item.FileName}}</a>
                            </li>
                        </ul>
                    </MDBCol>
                </MDBRow>

            </MDBModalBody>
            <MDBModalFooter>               
                <button type="button" class="btn btn-danger" @click="showRevenueSelected=false">
                    Cancel
                </button>

            </MDBModalFooter>
        </MDBModal>

    </div>
</template>
<style scoped>
    select option {

        background: rgba(0, 0, 0, 0.3);
        color: #fff;
        text-shadow: 0 1px 0 rgba(0, 0, 0, 0.4);
    }
    .table > :not(caption) > * > * {
        padding: 3px 5px;
    }
    .table > thead > tr > th {
        white-space: break-spaces;
        position: sticky;
        top: 0;
        background:#242424e6;
    }
    .freezeHeader{
        max-height:700px;
        overflow:auto;
    }
</style>
<script>
    //https://mdbootstrap.com/docs/b5/vue/components/modal/#docsTabsAPI
    import 'mdb-vue-ui-kit/css/mdb.min.css';
    import {
        //MDBBtn,
        MDBModal,
        MDBModalBody,
        MDBModalHeader,
        MDBModalTitle,
        MDBModalFooter,
        MDBCol, MDBRow,
        MDBInput
    } from 'mdb-vue-ui-kit';

    
    import 'xlsx';
    //import 'xlsx-style-vue';
    import 'sheetjs-style';
    const XLSX = require('sheetjs-style');


    //========== Datetime picker
    //https://vue3datepicker.com/installation/#global
    import Datepicker from 'vue3-date-time-picker';
    import 'vue3-date-time-picker/dist/main.css'

    import c from '@/mylib/common'
    import $ from 'jquery'
    import _ from 'lodash'
    import { mapState, mapActions } from 'vuex'
    export default {
        name: `ImportOrderCreate`,
        props: {

        },
        components: {
            Datepicker,
            MDBModal,
            MDBModalHeader,
            MDBModalTitle,
            MDBModalBody,
            MDBModalFooter,
            MDBCol, MDBRow,
            MDBInput,
        },
        data: function () {
            return {
                idEdit: 0,
                isLoadingFromEdit: false,
                SaveBtnTitle: 'Create',
                alphabetMap: { 9: 'I', 10: 'J', 11: 'K', 12: 'L', 13: 'M', 14: 'N', 15: 'O', 16: 'P', 17: 'Q', 18: 'R', 19: 'S', 20: 'T', 21: 'U', 22: 'V', 23: 'W', 24: 'X', 25: 'Y', 26: 'Z' },
                inventoryColumnCount: 8,
                isFreezeHeader: true,
                
                /*===== GroupSave form====*/
                CreateGroupSaleName:'',                
                GroupSaleSelectedIds: [],
                ProductGroupSaleData: {},
                showCreateGroupSales: false,
                showGroupSale: false,
                /*===== Custom Quantity form====*/
                showCustomQuantity: false,
                CustomQuantity: '',
                /*===== Revenue1 Selected form====*/
                RevenueUntilReceiveMonths: 0,
                RevenueUntilSoldOutMonths: 0,
                ReceiveMonthsNumOfPeak: 0,
                ReceiveMonthsNumOfRegular: 0,
                SoldOutMonthsNumOfPeak: 0,
                SoldOutMonthsNumOfRegular: 0,
                showRevenueSelected: false,
                RevenueType:1,
                Revenue1FileName: '',
                Revenue1FileId: '',
                Revenue2FileName: '',
                Revenue2FileId: '',                                
                /*===== Main form====*/
                Catalog: '',
                
                LastInventoryFileId: 0,
                LastInventoryFileName:'',
                CurrentInventoryFileId: 0,
                CurrentInventoryFileName: '',
                Quantity: '',                
                OrderDate: { "month": new Date().getMonth(), "year": new Date().getFullYear() },
                ReceiveDate: { "month": new Date().getMonth()+2, "year": new Date().getFullYear() },
                SoldOutDate: { "month": new Date().getMonth()+4, "year": new Date().getFullYear() },
                OrderName: '',
                OrderNameError: 'Please input a Name',
                dataCatalog: [],
                
                dataPeak: [],
                dataProduct: {},
                dataProductCatalog: [],
                
                
                dataOrder: {},
                dataInventory: {},
                dataGroupSale: {},
                dataRevenue: {},
                footerData: {},
                footerDataGroupSale: {},

                validateName: _.debounce(() => {
                    var nameEl = document.getElementById('ordername');
                    
                    this.call({ Action: 'ImportOrder/CheckName', Object: { Name: nameEl.value, Id: this.idEdit } }).then((rs) => {
                        nameEl.parentElement.classList.add('has-feedback');
                        nameEl.parentElement.classList.add('has-error');
                        var err = rs.Error;                        
                        if (err != "") {
                            err= 'Duplicate! Please choose another name.';
                        }
                        this.inputMark('ordername',err);
                        

                    });

                }, 1000),
            }
        },
        computed: mapState({
            isCheckedLogin: state => state.view.isCheckedLogin,
            groups: state => state.user.groups,
            
            index: state => state.user.index,
        }),
        methods: {
            ...mapActions({
                'loading': 'view/setLoading',                
                'showModal': 'view/showModal',
                'showToast': 'view/showToast',
                'call': 'api/call',
                'setGroups': 'user/setGroups',
            }),
            checkAcl(action) {
                return this.$store.state.session.acl.includes("ImportOrder/" + action);
            },
            checkFullAcl(router) {
                return this.$store.state.session.acl.includes(router);
            },
            start() {
                this.idEdit = parseInt(this.$route.params.id);

                
                this.getAllProduct();
                this.getAllOrder();
                this.getGroupSale(true);
                
                this.getDataPeak();
                
            },
            formatNumber(n) {
                if (isNaN(n)) return n;
                return c.formatNumber(n);
            },
            ViewHistory(code,name) {
                if (this.checkFullAcl('Product/ViewHistory'))
                    this.$store.dispatch("view/viewHistory", { code: code,name:name });
            },
            showFormular(name) {
                let content = '';
                if (name == 'TotalSales') {                    
                    for (let i = 0; i < this.GroupSaleSelectedIds.length; i++) {
                        content += this.alphabetMap[this.inventoryColumnCount + i + 1] + '+';
                    }
                    if (content.length > 0) content=content.substr(0, content.length - 1);                    
                }
                else if (name == 'Estimate') {
                    content = 'D-G-(H+(';                    
                    content += this.alphabetMap[this.inventoryColumnCount + this.GroupSaleSelectedIds.length + 1];
                    content += '-H)';                    
                }
                else if (name == 'Final') {                    
                    content = this.alphabetMap[this.inventoryColumnCount + this.GroupSaleSelectedIds.length + 3] + '-'+this.alphabetMap[this.inventoryColumnCount + this.GroupSaleSelectedIds.length + 6];                    
                }
                else if (name == 'ReceiveMonths') {
                    content = this.ReceiveMonthsNumOfRegular + "*E+" + this.ReceiveMonthsNumOfPeak + "*F";
                }
                else if (name == 'SoldOutMonths') {
                    content = this.SoldOutMonthsNumOfRegular + "*E+" + this.SoldOutMonthsNumOfPeak + "*F";
                }
                this.showModal({ isShow: true, title: '<b>' + name+'</b> Formular', content: content, type: 'success' });
            },
            showHtmlNumber(n) {
                const ftmNumber = c.formatNumber(n);
                let html = ftmNumber;
                let sign = "";
                if (n < 0) sign = "-";
                if (n < 0) {
                    html = `<span style="font-size:bold; color: red">${sign}${ftmNumber}</span>`;
                }
                return html;
            },
            ToExcel: function () {
                if (!this.isFormValid()) {
                    this.showToast({ isShow: true, title: '', content: "Form is invalid!", type: 'error' });
                    return;
                }

                //https://stackoverflow.com/questions/67033105/add-dynamic-columns-with-xlsx-sheetjs
                //https://stackoverflow.com/questions/50147526/sheetjs-xlsx-cell-styling

                var workbook = XLSX.utils.table_to_book(document.getElementById("datatableProductSold"))
                // Process Data (add a new row)
                var ws = workbook.Sheets["Sheet1"];

                ws['!cols'] = [
                    { width: 36 },  // first column
                    { width: 12 },
                    { width: 12 },
                    { width: 12 },
                    { width: 12 },
                    { width: 12 },
                    { width: 12 },
                    { width: 12 },
                    { width: 12 },
                    { width: 12 },
                    { width: 12 },
                    { width: 12 },
                    { width: 12 },
                    { width: 12 },
                    { width: 12 },  // 15th column
                ];
                
                
                
                let rowNum;
                let colNum;
                var range = XLSX.utils.decode_range(ws['!ref']);
                for (rowNum = range.s.r; rowNum <= range.e.r; rowNum++) {
                
                    for (colNum = range.s.c; colNum <= range.e.c; colNum++) {
                        let isMerged = false;
                        let Cell = ws[
                            XLSX.utils.encode_cell({ r: rowNum, c: colNum })
                        ];
                        let NextCell = ws[
                            XLSX.utils.encode_cell({ r: rowNum, c: colNum + 1 > range.e.c ? colNum : colNum+1 })
                        ];
                        
                        if (NextCell != Cell && NextCell == undefined) isMerged = true;
                       
                        //==== format cell ===
                        //if (rowNum == 0 && (colNum == 7
                        //    || colNum == (8 + this.GroupSaleSelectedIds.length))) {
                        //    Cell = {
                        //        s: {
                        //            border: {
                        //                right: {
                        //                    style: "thick",
                        //                    color: "000000"
                        //                }
                        //            }
                        //        },
                        //        t: 's',
                        //        v:''
                        //    };
                            
                        //}
                        
                        if (Cell == undefined) {
                            //mergecell
                            Cell = {};
                            Cell.s = {                                
                                border: {
                                    top: {
                                        style: "thin",
                                        color: "000000"
                                    },
                                    left: {
                                        style: "thick",
                                        color: "000000"
                                    },
                                    bottom: {
                                        style: "thin",
                                        color: "000000"
                                    },
                                    right: {
                                        style: "thin",
                                        color: "000000"
                                    },
                                }
                            }
                            continue;
                        }
                        else if (rowNum == 0) {
                            Cell.s = {
                                font: {
                                    name: 'Arial',
                                    sz: 14,
                                    bold: true,
                                    //color: { rgb: "95B3D7" }
                                },
                                alignment: {
                                    horizontal: 'center',
                                    vertical: 'center',
                                    wrapText: true
                                },
                                border: {
                                    top: {
                                        style: "thin",
                                        color: "000000"
                                    },
                                    left: {
                                        style: "thick",
                                        color: "000000"
                                    },
                                    bottom: {
                                        style: "thin",
                                        color: "000000"
                                    },
                                    right: {
                                        style: "thin",
                                        color: "000000"
                                    },
                                }
                            }
                        }
                        else if (rowNum == 1) {
                            Cell.s = {                               
                                font: {
                                    name: 'Arial',
                                    sz: 10,
                                    bold: true,
                                },
                                alignment: {
                                    vertical: (
                                        colNum == (11 + this.GroupSaleSelectedIds.length)
                                        || colNum == (12 + this.GroupSaleSelectedIds.length))
                                        ? 'top' : 'center',
                                    horizontal: 'center',
                                    wrapText: true
                                },
                                border: {
                                    top: {
                                        style: "thin",
                                        color: "000000"
                                    },
                                    left: {
                                        style: "thin",
                                        color: "000000"
                                    },
                                    bottom: {
                                        style: "thick",
                                        color: "000000"
                                    },
                                    right: {
                                        style: (colNum == 7
                                            || colNum == (8 + this.GroupSaleSelectedIds.length))
                                            ? "thick" : "thin",
                                        color: "000000"
                                    },
                                }
                            }
                        }
                        
                        else if (isMerged) {//wine color ROW
                            Cell.s = {
                                fill: { // background color
                                    patternType: "solid",
                                    fgColor: { rgb: "95B3D7" },
                                    bgColor: { rgb: "95B3D7" },
                                },
                                font: {
                                    name: 'Arial',
                                    sz: 12,
                                    bold: true,
                                },
                                alignment: {
                                    vertical:'center',
                                    horizontal: 'center',
                                    wrapText: true
                                },
                                border: {
                                    top: {
                                        style: "thin",
                                        color: "000000"
                                    },
                                    left: {
                                        style: "thin",
                                        color: "000000"
                                    },
                                    bottom: {
                                        style: "thin",
                                        color: "000000"
                                    },
                                    right: {
                                        style: "thin",
                                        color: "000000"
                                    },
                                }
                            }
                        }
                        else
                        {                          

                            //remove default note
                            if (colNum == (9 + this.GroupSaleSelectedIds.length) && Cell.v == "note") {
                                Cell.v = "";
                            }
                            //force text column if value is empty:
                            if (Cell.v=='' && Cell.t== 'z') {
                                Cell.t = 's';
                            }
                            

                            //styling cell
                            Cell.s = {
                                font: {
                                    name: 'Arial',
                                    sz: 10,
                                    bold: rowNum == range.e.r ? true : false, //bold final row
                                    color: {rbg:'ff0000'}
                                },
                                numFmt:'#,##0',
                                alignment: {
                                    horizontal: (colNum == 0 || colNum == 1 || colNum == (9 + this.GroupSaleSelectedIds.length))?'left': 'center',
                                    wrapText: true
                                },
                                border: {
                                    top: {
                                        style: rowNum == range.e.r?"thick": "thin",
                                        color: "000000"
                                    },
                                    left: {
                                        style: "thin",
                                        color: "000000"
                                    },
                                    bottom: {
                                        style: "thin",
                                        color: "000000"
                                    },
                                    right: {
                                        style: (colNum == 7
                                            || colNum == (8 + this.GroupSaleSelectedIds.length))
                                            ? "thick" : "thin",
                                        color: "000000"
                                    },
                                }
                            }

                            //styling order cell, if <0 then red
                            if ((colNum == (10 + this.GroupSaleSelectedIds.length) || colNum == (14 + this.GroupSaleSelectedIds.length)) && Cell.v<0) {
                                Cell.s.font.color = { rgb: "FF0000" };
                            }
                            //styling total colum
                            if (colNum == 0 && rowNum == range.e.r) {
                                Cell.s.alignment.horizontal = 'right';
                            }
                        }
                        
                    }                    
                }


                ws['!rows'] = [
                    { 'hpt': 35 },// height for row 1
                    { 'hpt': 130 },//height for row 2
                ]; 


                //adding
                XLSX.utils.sheet_add_aoa(ws, [["Created " + new Date().toISOString()]], { origin: -1 });

                // Package and Release Data (`writeFile` tries to write and save an XLSB file)
                XLSX.writeFile(workbook, this.OrderName+".xlsx");
            },
            //=========== Revenue Form ======================
            
            async selectRevenue(item) {
                if (item == undefined) return;
                this.loading(true);
                const rs = await this.call({
                    Action: 'ImportOrder/GetRevenueData', Object: {
                        FileId: item.Id,
                    }
                });
                this.loading(false);
                
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });

                } else {
                    if (this.RevenueType == 1) {
                        this.Revenue1FileName = item.FileName.replace('.xlsx', '').replace('.xls', '');
                        this.Revenue1FileId = item.Id;
                    } else {
                        this.Revenue2FileName = item.FileName.replace('.xlsx', '').replace('.xls', '');
                        this.Revenue2FileId = item.Id;
                    }

                    //mapping Revenue to Code
                    let mapRevenue = {};
                    for (let i = 0; i < rs.Data.length; i++) {
                        mapRevenue[rs.Data[i].Code] = rs.Data[i].Sold;
                    }
                    //assign data
                    for (let i = 0; i < this.dataProductCatalog.length; i++) {
                        if (this.RevenueType == 1) this.dataProductCatalog[i].Revenue1 = mapRevenue[this.dataProductCatalog[i].Code];
                        else this.dataProductCatalog[i].Revenue2 = mapRevenue[this.dataProductCatalog[i].Code];
                    }
                    this.showRevenueSelected = false;
                }                
                
            },
            //=========== CustomQuantity Form ======================
            validCustomQuantity() {
                if (!document.getElementById('CustomQuantity').checkValidity() || this.CustomQuantity < 1000) {
                    $('#CustomQuantity').addClass('is-invalid');
                    $('#CustomQuantity').removeClass('is-valid');
                } else {
                    $('#CustomQuantity').addClass('is-valid');
                    $('#CustomQuantity').removeClass('is-invalid');
                }
            },
            doCustomQuantity() {
                if (!document.getElementById('CustomQuantity').checkValidity() || this.CustomQuantity < 1000) {
                    $('#CustomQuantity').addClass('is-invalid');
                    return;
                }
                this.Quantity = this.CustomQuantity;
                this.showCustomQuantity = false;
            },
            //=========== CreateGroupSale Form ======================
            showCreateGroupSalesPopup() {
                this.CreateGroupSaleName = '';
                this.showCreateGroupSales = true;
            },
            validCreateGroupSaleName() {
                let isValid = true;
                let error = '';
                let name = this.CreateGroupSaleName.trim();
                let regex = /^([a-zA-Z0-9\s\-_.@]){0,}$/;
                if (!regex.test(name)) {
                    error = "Only accept @.-_";
                    isValid = false;
                }
                else if (name.length == 0) {
                    error = "Please input a Name!";
                    isValid = false;
                }
                //check length
                else if (name.length > 50) {
                    error = "Should not exceed 50 characters!";
                    isValid = false;
                }
                else //check duplicate name
                {                    
                    var keys = Object.keys(this.dataGroupSale);
                    for (let i = 0; i < keys.length; i++) {                        
                        if (this.dataGroupSale[keys[i]].Name == name) {
                            error = "GroupName is existed!";
                            isValid = false;
                            break;
                        }
                    }
                }

                if (!isValid) {
                    $('#CreateGroupSaleName').addClass('is-invalid');
                    $('#CreateGroupSaleName').removeClass('is-valid');
                    $('#CreateGroupSaleName~.invalid-feedback')[0].innerHTML = error;
                } else {
                    $('#CreateGroupSaleName').removeClass('is-invalid');
                    $('#CreateGroupSaleName').addClass('is-valid');
                }

                return isValid;
            },
            async doCreateGroupSales() {
                //======= validate =========
                //$('#editForm').addClass('was-validated');

                let isValid = true;
                isValid = isValid && this.validCreateGroupSaleName();

                if (!isValid) {
                    this.showToast({ isShow: true, title: '', content: 'Form is invalidated.', type: 'error' });
                    return;
                }

                //======== do submit =========
                this.loading(true);

                const rs = await this.call({
                    Action: 'GroupSale/Save', Object: {
                        Id: 0,
                        Name: this.CreateGroupSaleName,
                    }
                });
                this.loading(false);
                
                if (rs.Error != "") {
                    this.showToast({ isShow: true, title: '', content: rs.Error, type: 'error' });
                } else {                  
                    this.showToast({
                        isShow: true, title: '', content: `Create GroupSale <span style="font-weight:bold"> ${this.Name}</span> <span style="font-weight: bold;color: blue;">successful!</span> `, type: 'success'
                    });
                    
                    var groupsale = {};
                    groupsale[rs.Data["Id"]]=rs.Data;
                    this.concatGroupSale(groupsale,"new");
                    this.showCreateGroupSales = false;
                }
            },
            concatGroupSale(groupSales,type) {
                const _this = this;
                Object.keys(groupSales).forEach(i => {
                    if (_this.dataGroupSale[i] == undefined) {
                        _this.dataGroupSale[i] = groupSales[i];
                        if(type=="removed")
                            _this.dataGroupSale[i].IsRemoved = true;
                        else if (type == "new")
                            _this.dataGroupSale[i].IsNew = true;
                    }
                });
            },
            //=========== GroupSale Adding Form ======================
            checkGroupSaleSelected(itemid) {
                return this.GroupSaleSelectedIds.includes(itemid);
            },
            doGroupSaleAdd() {
                this.GroupSaleSelectedIds = $.map($('input[name="GroupSaleSelected"]:checked'), function (c) { return c.value * 1; });
                //remove the ProductGroupSale if not selected
                Object.keys(this.ProductGroupSaleData).forEach((key) => {                    
                    key = key * 1;//convert to int                    
                    if (!this.GroupSaleSelectedIds.includes(key))
                        delete this.ProductGroupSaleData[key];
                });
               

                for (let i = 0; i < this.dataProductCatalog.length; i++) {
                    this.GroupSaleSelectedIds.forEach((item) => {                        
                        if (this.ProductGroupSaleData[item] == undefined)
                            this.ProductGroupSaleData[item] = {};
                        if (this.ProductGroupSaleData[item][this.dataProductCatalog[i].Code] == undefined)
                            this.ProductGroupSaleData[item][this.dataProductCatalog[i].Code] = {value:0,isEdit:false};
                    });                    
                }
                setTimeout(this.setFakeScroll, 100);
                this.showGroupSale = false;
                this.calculateOrder();
            },
            setFakeScroll() {
                if ($("#datatableProductSold").width() > $("#datatableProductSold").parent().width())
                    $('#fakeScroll').css("width", (document.getElementById('datatableProductSold').offsetWidth + 20) + "px");
                else
                    $('#fakeScroll').css("width", "0px");
            },
            saveProductOldOrder(code) {
                for (let i = 0; i < this.dataProductCatalog.length; i++) {
                    if (this.dataProductCatalog[i].Code == code) {
                        this.dataProductCatalog[i].OldOrder = $(`#ProductOldOrderData_${code}`).val();
                        this.dataProductCatalog[i].isOldOrderEdit = false;
                        break;
                    }
                }
                this.calculateOrder();
            },
            saveProductGroupSale(code, key) {
                if (!this.ProductGroupSaleData[key] || !this.ProductGroupSaleData[key][code]) return;
                
                this.ProductGroupSaleData[key][code].value = $(`#ProductGroupSaleData_${code}_${key}`).val();
                this.ProductGroupSaleData[key][code].isEdit = false;
                
                
                this.calculateOrder();
            },
            saveProductProductOrderNote(code) {                
                for (let i = 0; i < this.dataProductCatalog.length; i++) {
                    if (this.dataProductCatalog[i].Code == code) {
                        this.dataProductCatalog[i].ProductOrderNote = $(`#ProductProductOrderNote_${code}`).val();
                        this.dataProductCatalog[i].isEditProductOrderNote = false;
                        break;
                    }
                }
            },
           //=========== Main Form ======================
            reCalculateRevenueSold() {
                this.ReceiveMonthsNumOfPeak = 0;
                this.ReceiveMonthsNumOfRegular= 0;
                this.RevenueUntilReceiveMonths = 0;                
                if (this.OrderDate != null && this.ReceiveDate != null) {                    
                    let startdate = new Date();                    
                    startdate.setMonth(this.OrderDate.month);
                    startdate.setYear(this.OrderDate.year);
                    while (startdate.getFullYear() * 100 + startdate.getMonth() < this.ReceiveDate.year * 100 + this.ReceiveDate.month) {                        
                        if (this.dataPeak[startdate.getMonth()].IsPeak)
                            this.ReceiveMonthsNumOfPeak++;
                        else
                            this.ReceiveMonthsNumOfRegular++;
                        startdate.setMonth(startdate.getMonth() + 1);
                        this.RevenueUntilReceiveMonths++;
                    }
                }

                this.SoldOutMonthsNumOfPeak = 0;
                this.SoldOutMonthsNumOfRegular = 0;
                this.RevenueUntilSoldOutMonths = 0;
                if (this.SoldOutDate != null && this.ReceiveDate != null) {
                    let startdate = new Date();                    
                    startdate.setMonth(this.ReceiveDate.month);
                    startdate.setYear(this.ReceiveDate.year);
                    while (startdate.getFullYear() * 100 + startdate.getMonth() < this.SoldOutDate.year * 100 + this.SoldOutDate.month) {                        
                        if (this.dataPeak[startdate.getMonth()].IsPeak)
                            this.SoldOutMonthsNumOfPeak++;
                        else
                            this.SoldOutMonthsNumOfRegular++;
                        startdate.setMonth(startdate.getMonth() + 1);
                        this.RevenueUntilSoldOutMonths++;
                    }
                }

                
                //======== calculate data ===========
                for (let i = 0; i < this.dataProductCatalog.length; i++) {                   
                    this.dataProductCatalog[i].RevenueUntilReceive = 'N/A';
                    this.dataProductCatalog[i].RevenueUntilSoldOut = 'N/A';
                    this.dataProductCatalog[i].TotalSale = 0;
                    
                    this.dataProductCatalog[i].isEditProductOrderNote = false;
                    //=== Total Order
                    this.dataProductCatalog[i].ProductOrderNote = '';
                    this.dataProductCatalog[i].EstimateOrder = '';                    
                    this.dataProductCatalog[i].OldOrder = 0;
                    this.dataProductCatalog[i].isOldOrderEdit = false;
                    this.dataProductCatalog[i].FinalOrder = 0;

                    
                    //calculate peak count and regular count
                    if (this.OrderDate != null && this.ReceiveDate != null) {
                        this.dataProductCatalog[i].RevenueUntilReceive = this.dataProductCatalog[i].PeakSold * this.ReceiveMonthsNumOfPeak + this.dataProductCatalog[i].RegularSold * this.ReceiveMonthsNumOfRegular;
                    }
                    if (this.SoldOutDate != null && this.ReceiveDate != null) {
                        this.dataProductCatalog[i].RevenueUntilSoldOut = this.dataProductCatalog[i].PeakSold * this.SoldOutMonthsNumOfPeak + this.dataProductCatalog[i].RegularSold * this.SoldOutMonthsNumOfRegular;
                    }
                   
                }
                //calculate Order
                this.calculateOrder();
            },
            calculateOrder() {
                //init groupsale footer
                
                for (let j = 0; j < this.GroupSaleSelectedIds.length; j++) {
                    this.footerDataGroupSale[this.GroupSaleSelectedIds[j]] = 0;
                }
                for (let i = 0; i < this.dataProductCatalog.length; i++) {                    
                    if (this.CurrentInventoryFileId > 0) {
                        //calculate total sale
                        let total = 0;
                        this.GroupSaleSelectedIds.forEach((item) => {
                            total += this.ProductGroupSaleData[item][this.dataProductCatalog[i].Code].value * 1;
                        });
                        this.dataProductCatalog[i].TotalSale = total;
                        //auto generate sale for each groupsale
                        
                        if (this.dataProductCatalog[i].TotalSale == 0 && this.dataProductCatalog[i].RevenueUntilSoldOut>0) {

                            total = this.dataProductCatalog[i].RevenueUntilSoldOut;
                            let totalRemain = total;
                            let saleForEachGroup = Math.floor(total / this.GroupSaleSelectedIds.length);
                            this.GroupSaleSelectedIds.forEach((item) => {
                                if (totalRemain <= 0) return;
                                if (totalRemain > saleForEachGroup && totalRemain / saleForEachGroup < 2)
                                    this.ProductGroupSaleData[item][this.dataProductCatalog[i].Code].value = totalRemain;
                                else {
                                    this.ProductGroupSaleData[item][this.dataProductCatalog[i].Code].value = saleForEachGroup;
                                    totalRemain -= saleForEachGroup;
                                }
                            });
                            this.dataProductCatalog[i].TotalSale = total;
                        }


                        //calculate Order
                        this.dataProductCatalog[i].EstimateOrder = this.dataProductCatalog[i].CurrentInventory - this.dataProductCatalog[i].RevenueUntilReceive - (this.dataProductCatalog[i].RevenueUntilSoldOut + (this.dataProductCatalog[i].TotalSale - this.dataProductCatalog[i].RevenueUntilSoldOut));
                        this.dataProductCatalog[i].FinalOrder = this.dataProductCatalog[i].EstimateOrder * 1 + this.dataProductCatalog[i].OldOrder * 1;

                    }
                    
                }
                this.calculateFooter();
            },
            calculateFooter() {
                //======== footer data ===========     
                this.footerData.CurrentInventory = 0;
                this.footerData.LastInventory = 0;
                this.footerData.RegularSold = 0;
                this.footerData.PeakSold = 0;
                this.footerData.RevenueUntilReceive = 0;
                this.footerData.RevenueUntilSoldOut = 0;
                this.footerData.GroupSale = {};
                this.footerData.TotalSale = 0;
                this.footerData.EstimateOrder = 0;
                this.footerData.Revenue1 = 0;
                this.footerData.Revenue2 = 0;
                this.footerData.OldOrder = 0;
                this.footerData.FinalOrder = 0;

                for(let j = 0; j < this.GroupSaleSelectedIds.length; j++) {
                    this.footerDataGroupSale[this.GroupSaleSelectedIds[j]] = 0;
                }
                for (let i = 0; i < this.dataProductCatalog.length; i++) {                    
                    //calculate footer data
                    if (this.dataProductCatalog[i].EstimateOrder < 0)
                        this.footerData.EstimateOrder += this.dataProductCatalog[i].EstimateOrder;
                    if (this.dataProductCatalog[i].FinalOrder < 0)
                        this.footerData.FinalOrder += this.dataProductCatalog[i].FinalOrder;

                    
                    this.footerData.CurrentInventory += this.dataProductCatalog[i].CurrentInventory*1;
                    this.footerData.LastInventory += this.dataProductCatalog[i].LastInventory*1;

                    this.footerData.RegularSold += this.dataProductCatalog[i].RegularSold * 1;
                    this.footerData.PeakSold += this.dataProductCatalog[i].PeakSold * 1;
                    this.footerData.OldOrder += this.dataProductCatalog[i].OldOrder*1;
                    this.footerData.TotalSale += this.dataProductCatalog[i].TotalSale * 1;
                    this.footerData.Revenue1 += this.dataProductCatalog[i].Revenue1 * 1;
                    this.footerData.Revenue2 += this.dataProductCatalog[i].Revenue2 * 1;
                    //calculate footer data
                    this.footerData.RevenueUntilReceive += this.dataProductCatalog[i].RevenueUntilReceive
                    this.footerData.RevenueUntilSoldOut += this.dataProductCatalog[i].RevenueUntilSoldOut
                    
                    //calculate groupsale footer                    
                    for (let j = 0; j < this.GroupSaleSelectedIds.length; j++) {
                        this.footerDataGroupSale[this.GroupSaleSelectedIds[j]] += this.ProductGroupSaleData[this.GroupSaleSelectedIds[j]][this.dataProductCatalog[i].Code].value * 1;
                    }
                }
            },
            resetRevenueData() {
                for (let i = 0; i < this.dataProductCatalog.length; i++) {
                    this.dataProductCatalog[i].Revenue1 = '';
                    this.dataProductCatalog[i].Revenue2 = '';
                }
            },
            async getAllOrder() {
                this.loading(true);
                const rs = await this.call({ Action: 'ImportOrder/List' })
                this.loading(false);
                
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });
                } else {
                    
                    this.dataOrder = {};
                    for (let i = 0; i < rs.Data.length; i++) {
                        this.dataOrder[i] = rs.Data[i];                        
                    }
                }
            },
            async getById() {
                this.loading(true);
                const rs = await this.call({
                    Action: 'ImportOrder/GetById',
                    Object: {
                        Id: this.idEdit
                    }
                });
                this.loading(false);
                
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });
                } else {
                    
                    const _this = this;                    
                    this.loading(true);
                    this.SaveBtnTitle = "Save";
                    setTimeout(async function () {
                        _this.isLoadingFromEdit = true;
                        _this.OrderName = rs.Data.Name;
                        document.getElementById('ordername').value = _this.OrderName;
                        _this.OrderNameError = '';
                        _this.Catalog = rs.Data.Catalog;
                        _this.Quantity = rs.Data.Quantity;
                        const orderdate = new Date(rs.Data.OrderDate);
                        _this.OrderDate.month = orderdate.getMonth();
                        _this.OrderDate.year = orderdate.getFullYear();
                        const recdate = new Date(rs.Data.ReceiveDate);                        
                        _this.ReceiveDate.month = recdate.getMonth();
                        _this.ReceiveDate.year = recdate.getFullYear();
                        const soldoutDate = new Date(rs.Data.SoldOutDate);
                        _this.SoldOutDate.month = soldoutDate.getMonth();
                        _this.SoldOutDate.year = soldoutDate.getFullYear();
                        //========= load inventory data ===========
                        _this.LastInventoryFileId = rs.Data.LastInventoryFileId;
                        _this.LastInventoryFileName = _this.dataInventory[rs.Data.LastInventoryFileId].FileName;
                        _this.CurrentInventoryFileId = rs.Data.CurrentInventoryFileId;
                        _this.CurrentInventoryFileName = _this.dataInventory[rs.Data.CurrentInventoryFileId].FileName;
                        _this.reCalculateRevenueSold();

                        //========= load DS data ===========
                        _this.RevenueType = 1;
                        await _this.selectRevenue(_this.dataRevenue[rs.Data.Revenue1FileId]);
                        _this.RevenueType = 2;
                        await _this.selectRevenue(_this.dataRevenue[rs.Data.Revenue2FileId]);
                        //========== parse Json data 
                        
                        _this.concatGroupSale(JSON.parse(rs.Data.DataGroupSale),"removed");
                        
                        
                        _this.GroupSaleSelectedIds = JSON.parse(rs.Data.GroupSaleSelectedIds);
                        _this.ProductGroupSaleData = JSON.parse(rs.Data.ProductGroupSaleData);
                        _this.dataProductCatalog = JSON.parse(rs.Data.DataProductCatalog);
                        _this.isLoadingFromEdit = false;
                        _this.calculateFooter();
                        _this.loading(false);
                    }, 300);
                }
            },
            async getAllProduct() {
                this.loading(true);
                const rs = await this.call({ Action: 'ImportOrder/GetAllProduct' })
                this.loading(false);                
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });
                } else {                    
                    this.dataProduct = rs.Data;
                    
                    for (let i = 0; i < rs.Data.length; i++) {
                        const cat = rs.Data[i].Catalog.trim();
                        
                        if (!this.dataCatalog.includes(cat))
                            this.dataCatalog.push(cat);

                        const color = rs.Data[i].SubCatalog2;
                        if (color == '') color = 'Unknow';
                        
                    }
                    //sort by alphabet
                    this.dataCatalog.sort((a, b) => a.localeCompare(b));
                    
                }
            },
            async getAllInventory() {
                this.loading(true);
                const rs = await this.call({ Action: 'ImportOrder/GetAllInventory' })
                this.loading(false);
                
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });                    
                } else {
                    
                    this.dataInventory = {};
                    this.InventoryFileIds = [];
                    for (let i = 0; i < rs.Data.length; i++) {
                        this.InventoryFileIds.push(rs.Data[i].Id);
                        this.dataInventory[rs.Data[i].Id] = rs.Data[i];
                    }
                    this.InventoryFileIds.sort((a, b) => b > a ? 1 : -1);                    
                    this.getAllRevenue();
                }
            },
            async getAllRevenue() {                
                this.loading(true);
                const rs = await this.call({ Action: 'ImportOrder/GetAllRevenue'});
                this.loading(false);
                
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });
                    
                } else {
                    
                    this.dataRevenue= {};
                    for (let i = 0; i < rs.Data.length; i++) {
                        this.dataRevenue[rs.Data[i].Id] = rs.Data[i];
                    }
                    if (this.idEdit > 0)
                        this.getById();
                }
            },
            async getDataPeak() {                
                this.loading(true);
                const rs = await this.call({ Action: 'ImportOrder/GetPeakData' });
                this.loading(false);
                
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });

                } else {
                    this.dataPeak = rs.Data;
                }
            },
            async getGroupSale(isStart) {
                isStart = isStart || false;
                this.loading(true);
                const rs = await this.call({ Action: 'ImportOrder/GetGroupSale' });
                this.loading(false);
                
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });

                } else {
                    this.dataGroupSale = {};
                    for (let i = 0; i < rs.Data.length; i++) {
                        this.dataGroupSale[rs.Data[i].Id] = rs.Data[i];
                        this.footerDataGroupSale[rs.Data[i].Id] = 0;
                    }
                    if (isStart)
                        this.getAllInventory();                    
                }
            },
            async getInventoryData(fileId, isCurrent) {
                isCurrent = isCurrent || false;                
                this.loading(true);
                const rs = await this.call({
                    Action: 'ImportOrder/GetInventoryData', Object: {
                        FileId: fileId,
                        Catalog: this.Catalog
                    }
                });
                this.loading(false);
                
                if (rs.Error != "") {
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });

                } else {
                    this.footerData.CurrentInventory = 0;
                    this.footerData.LastInventory = 0;
                    for (let i = 0; i < this.dataProductCatalog.length; i++) {                    
                        if (rs.Data[this.dataProductCatalog[i].Code] != undefined)
                            if (isCurrent)
                                this.dataProductCatalog[i].CurrentInventory = rs.Data[this.dataProductCatalog[i].Code];
                            else
                                this.dataProductCatalog[i].LastInventory = rs.Data[this.dataProductCatalog[i].Code];
                        else
                            if (isCurrent)
                                this.dataProductCatalog[i].CurrentInventory = 0;
                            else
                                this.dataProductCatalog[i].LastInventory = 0;
                        // calculate footer data
                        this.footerData.CurrentInventory += this.dataProductCatalog[i].CurrentInventory;
                        this.footerData.LastInventory += this.dataProductCatalog[i].LastInventory;
                    }
                    if (isCurrent)
                        this.reCalculateRevenueSold();
                }
            },
            nameKeyup() {
                var nameEl = document.getElementById('ordername');
                this.OrderName = nameEl.value;
                if (!nameEl.value || nameEl.value.trim() == "") {
                    this.inputMark('ordername', 'Please input a name.');
                    this.validateName.cancel();
                } else {
                    nameEl.nextElementSibling.nextElementSibling.innerHTML = 'Checking...';
                    this.validateName();
                }
            },
            isFormValid() {
                //validate
                var nameEl = document.getElementById('ordername');
                var isValid = true;
                if (!nameEl.value || nameEl.value.trim() == "") {
                    nameEl.focus();
                    nameEl.parentElement.classList.add('has-error');
                    nameEl.parentElement.classList.add('has-feedback');
                    isValid = false;
                }
                if (this.OrderDate == null) {
                    if (isValid)
                        $('html, body').animate({ scrollTop: $("#OrderDateError").offset().top - 120 }, 100);
                    isValid = false;
                }
                if (this.ReceiveDate == null) {
                    $('#ReceiveDateError').removeClass('d-none');
                    //if (!isValid)$('html, body').animate({ scrollTop: $("#ReceiveDateError").offset().top - 120 }, 0);
                    isValid = false;
                }
                if (this.SoldOutDate == null) {
                    if (isValid) $('html, body').animate({ scrollTop: $("#SoldOutDateError").offset().top - 120 }, 100);
                    isValid = false;
                }
                if (this.Quantity < 1000) {
                    if (isValid) $('html, body').animate({ scrollTop: $("#QuantityError").offset().top - 120 }, 100);
                    isValid = false;
                }
                if (this.LastInventoryFileId == 0) {
                    if (isValid) $('html, body').animate({ scrollTop: $("#LastInventoryFileError").offset().top - 120 }, 100);
                    isValid = false;
                }
                if (this.CurrentInventoryFileId == 0) {
                    if (isValid) $('html, body').animate({ scrollTop: $("#CurrentInventoryFileError").offset().top - 120 }, 100);
                    isValid = false;
                }
                return isValid;
            },
            async save() {
                
                if (!this.isFormValid()) {
                    this.showToast({ isShow: true, title: '', content: "Form is invalid!", type: 'error' });
                    return;
                }
                //get selected acl
                const acl = [];
                $('.jstree-clicked:not(.jstree-parent)').each((i, item) => {
                    acl.push(item.getAttribute("data"));
                });

                let Action = "ImportOrder/Save";
                //let data = {UserName:}
                

                this.loading(true);
                const rs = await this.call({
                    Action: Action, Object: {
                        Id: this.idEdit,
                        Name: this.OrderName,
                        Quantity: this.Quantity,
                        Catalog: this.Catalog,
                        OrderDate: this.OrderDate,
                        ReceiveDate: this.ReceiveDate,
                        SoldOutDate: this.SoldOutDate,
                        LastInventoryFileId: this.LastInventoryFileId,
                        CurrentInventoryFileId: this.CurrentInventoryFileId,
                        Revenue1FileId: this.Revenue1FileId,
                        Revenue2FileId: this.Revenue2FileId,
                        //==== order detail====
                        DataProductCatalog: this.dataProductCatalog,
                        ProductGroupSaleData: this.ProductGroupSaleData,
                        DataGroupSale: this.dataGroupSale,
                        GroupSaleSelectedIds: this.GroupSaleSelectedIds,
                    }
                })
                this.loading(false);

                if (rs.Error != "") {                    
                    //show modal with callback name = loginView to check it whenever modal confirm triggerConfirm
                    this.showModal({ isShow: true, title: 'Error', content: rs.Error, type: 'error' });

                } else {
                    this.showToast({ isShow: true, title: '', content: (this.idEdit != 0?'Save':'Create')+` Order "${this.OrderName}" successfull`, type: 'success' });
                    this.$router.push("/ImportOrder");
                }
            },
           
            inputMark(elId, msg) {
                var nameEl = document.getElementById(elId);
                msg = msg || '';//default value
                this.OrderNameError = msg;
                //if success
                if (msg == '') {
                    nameEl.parentElement.classList.remove('has-error');
                    nameEl.parentElement.classList.add('has-success');
                    nameEl.nextElementSibling.classList.remove('ion-alert-circled');
                    nameEl.nextElementSibling.classList.add('ion-checkmark-circled');
                }
                //if error
                else {
                    nameEl.parentElement.classList.add('has-error');
                    nameEl.parentElement.classList.remove('has-success');
                    nameEl.nextElementSibling.classList.add('ion-alert-circled');
                    nameEl.nextElementSibling.classList.remove('ion-checkmark-circled');
                    nameEl.focus();
                }
            }
        },
        mounted() {
            //if already login then start,
            //else will watch isCheckedLogin to catch its change
            //to call start function
            if (this.isCheckedLogin) this.start();
            document.getElementById('fakeScrollDiv').onscroll = function () {                
                document.getElementById('mainScrollDiv').scrollLeft = document.getElementById('fakeScrollDiv').scrollLeft;
            };
            document.getElementById('mainScrollDiv').onscroll = function () {                
                document.getElementById('fakeScrollDiv').scrollLeft = document.getElementById('mainScrollDiv').scrollLeft;
            };

            
        },
        watch: {
            //catch status from not login to logged in
            //to call start function.
            //Apply for reload page issue
            isCheckedLogin(newVal) {
                if (newVal) {
                    this.start();
                }
            },
            Catalog(newVal) {                
                if (newVal != '' && !this.isLoadingFromEdit) {
                    
                    //return;
                    this.dataProductCatalog = [];
                    const cat = this.Catalog.replace(/ /g, '').toLowerCase();
                    let color = '';
                    for (let i = 0; i < this.dataProduct.length; i++) {                        
                        if (this.dataProduct[i].Catalog.replace(/ /g, '').toLowerCase() == cat) {
                            let item = this.dataProduct[i];

                            if (color != item.SubCatalog2) item["changeColor"] = true;
                            else item["changeColor"] = false;
                            this.dataProductCatalog.push(item);
                            color = item.SubCatalog2;
                        }
                    }
                    
                    this.LastInventoryFileId = 0;
                    this.LastInventoryFileName = '';
                    this.CurrentInventoryFileId = 0;
                    this.CurrentInventoryFileName = '';
                    this.Revenue1FileId = 0;
                    this.Revenue1FileName = '';
                    this.Revenue2FileId = 0;
                    this.Revenue2FileName = '';
                    
                    this.GroupSaleSelectedIds = [];
                    this.ProductGroupSaleData = {};
                    this.resetRevenueData();
                    this.reCalculateRevenueSold();
                }
                //scroll data
                setTimeout(this.setFakeScroll, 100);
            },
            LastInventoryFileId(newVal) {
                if (this.isLoadingFromEdit) return;
                if (newVal != 0) {
                    
                    if (this.dataInventory[newVal] != undefined) {
                        this.LastInventoryFileName = this.dataInventory[newVal].FileName;
                        this.getInventoryData(newVal);
                    }
                }
            },
            CurrentInventoryFileId(newVal) {
                if (this.isLoadingFromEdit) return;
                if (newVal != 0) {
                    
                    if (this.dataInventory[newVal] != undefined) {
                        this.CurrentInventoryFileName = this.dataInventory[newVal].FileName;
                        this.getInventoryData(newVal,true);
                    }
                }
            },
            OrderDate() {                
                if(this.OrderDate!=null)
                    $('#OrderDateError').addClass('d-none');
                this.reCalculateRevenueSold();
            },
            ReceiveDate() {
                if (this.ReceiveDate != null)
                    $('#ReceiveDateError').addClass('d-none');
                this.reCalculateRevenueSold();
            },
            SoldOutDate() {
                if (this.SoldOutDate != null)
                    $('#SoldOutDateError').addClass('d-none');
                this.reCalculateRevenueSold();
            },
            CustomQuantity() {
                if (!document.getElementById('CustomQuantity')) return;
                setTimeout(this.validCustomQuantity, 100);
            },
            CreateGroupSaleName() {
                setTimeout(this.validCreateGroupSaleName, 100);
            }
        }
    };
</script>
